
const getScreenType = () => {
    let screenType = {type: "", orientation: ""}
    let ar = window.screen.height / window.screen.width
    if (ar > 1.333){
        screenType.type = "mobile"
        screenType.orientation = "portrait"
    } else if (ar <= 1.333 ) {
        screenType.type = "desktop"
    }
    return screenType;
}

export default getScreenType;