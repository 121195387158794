import { Container, Typography } from '@mui/material';
import React, { useState } from 'react';

const CopyrightText = (props) => {


    return (
        <div>
            <div style={{
                padding: '20px',

                height: '40px',
                display: 'flex',
                justifyContent: 'center', fontWeight:"100",
                alignItems: 'center', color: "#f4f4f4", font:'Segoe UI'
            }}>
                <Typography variant='subtitle2'>
                    © 2024. All Rights Reserved. Polyview Health, Inc.
                </Typography>

            </div>
        </div>
    )
};

export default CopyrightText;