import React, { useRef, useEffect, useState } from 'react';
import { Button, Typography, Box , List, Divider} from '@mui/material';
import Drawer from '@mui/material/Drawer';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import backdrop from './assets/missionBackdrop.png';
import earthBackdrop from './assets/globeBackdrop.png';
import logo from './assets/icon_white.png';
import largeLogo from './assets/pvhlogolarge.png';
import gradients from './assets/gradient.png';
import About from './components/About/About';
import ContactUs from './components/ContactUs/ContactUs';
import { useInView } from 'react-intersection-observer';
import './sections.css';
import getScreenType from './getScreenType';
import animatedBackrop from './assets/animatedBackdrop.gif'


const Mission = () => {
    var useMobile = getScreenType().type == 'mobile'

    const { ref, inView, entry } = useInView();
    const seen = useRef(false)

    useEffect(() => {
        if (inView) {
            seen.current = true;
        }
    }, [inView])


    if (!useMobile)
    return (
        <div id="mission" style={{ width: "98%", height: "100%", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", overflow: 'hidden' }}>
            <div ref={ref} className={(inView || seen.current) ? "mission-visible" : "mission-hidden"}>
                <img src={logo} width={"100px"} style={{ margin: "50px" }}></img>
                <Typography style={{ color: "white", maxWidth: "60%", paddingBottom: "100px", fontSize: "30px", textAlign: 'center' }}>Polyview Health deploys cutting-edge design and cloud AI services to make the telehealth visit <b>better than being there</b>, on a data and biometrics platform that deters and detects fraud.</Typography>
            </div>
            <img src={backdrop} style={{ width: "98vw", zIndex: "-2", position: "absolute", opacity: "0.6" }}></img>

        </div>
    )
     else return (
        <div id="mission" style={{overflow:"clip", width: "98%", height: "100%", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", overflow: 'hidden' }}>
            <div ref={ref} className={(inView || seen.current) ? "mission-visible" : "mission-hidden"}>
                <img src={logo} width={"70px"} style={{ margin: "50px" }}></img>
                <Typography style={{ color: "white", maxWidth: "90%", paddingBottom: "100px", fontSize: "30px", textAlign: 'left' }}>Polyview Health deploys cutting-edge design and cloud AI services to make the telehealth visit <b>better than being there</b>, on a data and biometrics platform that deters and detects fraud.</Typography>
            </div>
            <img src={backdrop} style={{ overflow:"hidden", top:"100%", objectFit:"cover", width:"100%", height:"110vh", zIndex: "-2", scale:1, position: "absolute", opacity: "0.6" }}></img>

        </div>
     )

}

const DrawerMenu = () => {

    const [drawerOpen, setDrawerOpen] = useState(false)

    return (<div>
        <Button variant='contained' onClick={() => setDrawerOpen(!drawerOpen)} style={{
            position: 'fixed',
            backgroundColor: '#2d2d2d',
            boxShadow:"rgba(0,0,0,0.5) 0px 4px 4px 0px",
            bottom: drawerOpen ? "230px" : "30px",
            left:"50%",
            transform:"translate(-50%,0%)", borderRadius:"25px",
            minWidth:"unset", transition:"all 0.3s smooth",
            width:"50px",
            height:"50px",
            zIndex:1201
        }}>
            <img width={"25px"} src={logo}></img>
        </Button>
        <Drawer
            anchor="bottom"
            open={drawerOpen}
        >
             <Box
      sx={{ width: "auto" ,background:"#2d2d2d", color:"white"}}
      role="presentation"
      onClick={() => setDrawerOpen(false)}
    >
      <List>
        <ListItem disablePadding>
            <ListItemButton onClick={() => document.getElementById("landing").scrollIntoView(true)}>
              <ListItemText primary={"Home"} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={() => document.getElementById("mission").scrollIntoView(true)}>
              <ListItemText primary={"Mission"} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={() => document.getElementById("about").scrollIntoView(true)}>
              <ListItemText primary={"Who we are"} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={() => document.getElementById("contact").scrollIntoView(true)}>
              <ListItemText primary={"Contact"} />
            </ListItemButton>
          </ListItem>
          <Divider/>
          <ListItem disablePadding >
            <a className='toolbar-button-mobile' href='http://console.polyviewhealth.com'>Sign In</a>
          </ListItem>
      </List>
      </Box>
        </Drawer>
    </div>)
}

const Toolbar = () => {
    return (<div style={{
        width: '100%',
        position: 'absolute',
        top: '0px',
        height: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: "center",
        zIndex: 1
    }}>
        <img src={logo} style={{ height: "25px", padding: "10px" }}></img>
        <div style={{
            display: 'flex',
            gap: '100px', paddingRight: "50px"
        }}>
            <button className='toolbar-button' variant='contained' onClick={() => document.getElementById("landing").scrollIntoView({ behavior: "smooth" })}>Home</button>
            <button className='toolbar-button' variant='contained' onClick={() => document.getElementById("mission").scrollIntoView({ behavior: "smooth" })}>Mission</button>
            <button className='toolbar-button' variant='contained' onClick={() => document.getElementById("about").scrollIntoView({ behavior: "smooth" })}>Who we are</button>
            <button className='toolbar-button' variant='contained' onClick={() => document.getElementById("contact").scrollIntoView({ behavior: "smooth" })}>Contact</button>
            <a className='toolbar-button' href='http://console.polyviewhealth.com'>Sign In</a>


        </div>

    </div>)
}


const Landing = () => {
    var useMobile = getScreenType().type == 'mobile'

    if (useMobile == false)
    return (
        <div id="landing" style={{ width: "98vw", height: "100vh", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
            {/*<div style={{ width: "98vw", height: "343vh", zIndex: "-2", backgroundColor: "black", position: "absolute" }}></div>*/}
            <img src={gradients} style={{ width: "98vw", zIndex: "0", position: "absolute" }}></img>

            <div style={{ width: "50vw" }}>
                <img src={earthBackdrop} className="animatePicture" style={{ width: "110%", zIndex: "-2", position: "sticky", marginTop: "68px" }}></img>
            </div>
            <div style={{
                width: '50vw',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center', flexDirection: "column"
            }}>
                <img src={largeLogo} style={{ width: "70%", zIndex: "1" }}></img>
                <Typography style={{ color: "white", maxWidth: "60%", zIndex: "1", fontSize: "30px", textAlign: 'center', fontWeight: 100, fontFamily: 'Segoe UI' }}>Better than being there.</Typography>

            </div>

        </div>
    )

    else {
        return (
            <div id="landing" style={{height:"100vh", width:"98%", display:"flex", flexDirection:"column", alignItems:"center"}}>
            <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", paddingTop:"100px" }}>
                            <img src={largeLogo} style={{ width: "70%", zIndex: "1" }}></img>
                            <Typography style={{ color: "white", zIndex: "1", fontSize: "20px", textAlign: 'right', fontWeight: 100, fontFamily: 'Segoe UI' }}>Better than being there.</Typography>
            </div>
            <img src={earthBackdrop} className="animatePicture" style={{ width: "99%", zIndex: "-2", position: "absolute", top:"100%", translate:"0px -99%" }}></img>
            <img src={animatedBackrop} style={{width:"99%", objectFit:"cover", zIndex:-10, height:"100%", position:"fixed", top:"0px"}}/>
            </div>
        )
    }

}

const AboutSection = () => {
    return (
        <About />
    )
}

const ContactSection = () => {
    return (
        <ContactUs />
    )
}

export { Mission, Landing, DrawerMenu, AboutSection, ContactSection, Toolbar }