import  React, {useRef, useEffect, useState} from 'react';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import GregPic from '../../assets/teamPics/greg.jpg';
import NickPic from '../../assets/teamPics/nickPictureCrop.jpg';
import JasonPic from '../../assets/teamPics/JasonHighRes.jpg';
import DimitriPic from '../../assets/teamPics/Dimheadshot.jpg';
import text from '../../assets/text.png'
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import CircleIcon from '@mui/icons-material/Circle';
import { useInView } from 'react-intersection-observer';
import getScreenType from '../../getScreenType';

import './index.css';

function About() {

    const AutoSwipeableViews = autoPlay(SwipeableViews);

    const {ref: headerRef, inView: headerInView, entry: headerEntry} = useInView()
    const {ref: cardsRef, inView: cardsInView, entry: cardEntry} = useInView()

    const cardsSeen = useRef(false)


    const [page, setPage] = useState(0)
    const useMobile = getScreenType().type == 'mobile'

    useEffect(() => {
        if (cardsInView){
            cardsSeen.current = true;
        }
    }, [cardsInView])

    const headerSeen = useRef(false)

    useEffect(() => {
        if (headerInView){
            headerSeen.current = true;
        }
    }, [headerInView])


    const setDots = (index, type) => {
        console.log(index)
        let litRounded = Math.round(index)
        if (page != litRounded)
            setPage(litRounded)
      }
     
    const GetDots = (lit, total) => {
        let arr = []
        for (let i =0; i < total; i++){
            if (i == lit)
                arr.push(<CircleIcon sx={{color:"white", width:"10px"}}></CircleIcon>)
            else 
                arr.push(<CircleOutlinedIcon sx={{color:"white", width:"10px"}}></CircleOutlinedIcon>)
    
        }
        return (
            <div style ={{display:"flex", flexDirection:"row", justifyContent:"center", width:"100%", gap:"5px",marginBottom:"45px", alignItems:"center" , height:"5px"}}>
                {arr}
            </div>
        )
      }


      if (!useMobile)
        return (
    <div id="about" style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", marginBottom:"50px"}}>
    
    <div>
      <Box className="about">
        <Box ref={headerRef} className={headerInView || headerSeen.current ? "about_mission" : "about_mission_hidden"}>
        Who we are
        </Box>

        <Box className="about_container" ref={cardsRef}>
        <Box className={"aboutCardGrid"} style={cardsInView || cardsSeen.current ? {} : {transform:"translateX(-70px)"}}>
                <Box className="about_container_card_photo2" >
                <img className='about_container_card_photo2_image'  src={DimitriPic} alt="image" style={{width: "100%", height: "100%", borderRadius:"50%" , objectFit: "cover"}}/>
                </Box>
                 <Box className="about_card2">
                 <img src={text} width={"100%"}/>
                    {/*<Typography  style={{fontWeight: 500, fontSize: '20px'}}>Dimitri Arges</Typography>
                    <Typography style={{fontWeight: 100, fontSize: '15px', marginBottom:"20px"}}>Co-Chief Executive Officer</Typography>
                    <Typography sx={{textAlign: 'left', fontSize: '16px', fontWeight: '200'}}>Dimitri Arges is a serial digital health entrepreneur since industry inception, who was a founder of 4L Data Intelligence, an AI pioneer in health care fraud, waste and abuse (FWA) prevention. Dimitri also co-founded the Connected Health initiative at the UCSD Center for Wireless Communications.</Typography>
                    */}
                 </Box>
            </Box>
            <Box className={"aboutCardGrid"} style={cardsInView || cardsSeen.current ? {} : {transform:"translateX(-333px)"}}>
                <Box className="about_container_card_photo2" >
                <img className='about_container_card_photo2_image'  src={JasonPic} alt="image" style={{width: "100%", height: "100%", borderRadius:"50%" , objectFit: "cover"}}/>
                </Box>
                 <Box className="about_card2">
                    <Typography  style={{fontWeight: 500, fontSize: '20px'}}>Jason Hwang</Typography>
                    <Typography style={{fontWeight: 100, fontSize: '15px', marginBottom:"20px"}}>Co-Chief Executive Officer</Typography>
                    <Typography sx={{textAlign: 'left', fontSize: '16px', fontWeight: '200'}}>Jason Hwang is an internal medicine physician who was a co-founder and chief medical officer of Lemonaid Health, a telehealth provider focused on highly-automated, asynchronous delivery of primary care. Jason is also one of the authors of The <i>Innovator's Prescription</i> and was a founder and executive director of health care research at the Clayton Christensen Institute for Disruptive Innovation.</Typography>
                 </Box>
            </Box>
            <Box className={"aboutCardGrid"} style={cardsInView || cardsSeen.current ? {} : {transform:"translateX(-666px)"}}>
                <Box className="about_container_card_photo2" >
                <img className='about_container_card_photo2_image'  src={GregPic} alt="image" style={{width: "100%", height: "100%", borderRadius:"50%" , objectFit: "cover"}}/>
                </Box>
                 <Box className="about_card2">
                 <Typography style={{fontWeight: 500, fontSize: '20px'}}>Greg Deocampo</Typography>
                 <Typography style={{fontWeight: 100, fontSize: '15px', marginBottom:"20px"}}>Chief Technology Officer</Typography>

                    <Typography sx={{textAlign: 'left',
fontSize: '16px',
fontWeight: '200'}}>Greg Deocampo is a technology executive and entrepreneur whose work includes co-founding the Oscar-winning Adobe After Effects and Obscura Digital, whose projected reality systems were acquired by Madison Square Garden and deployed in the newly unveiled MSG Sphere.</Typography>
                 </Box>
            </Box>
            <Box className={"aboutCardGrid"} style={cardsInView || cardsSeen.current ? {} : {transform:"translateX(-999px)"}}>
                <Box className="about_container_card_photo2" >
                <img className='about_container_card_photo2_image'  src={NickPic} alt="image" style={{width: "100%", height: "100%", borderRadius:"50%" , objectFit: "cover"}}/>
                </Box>
                 <Box className="about_card2">
                    <Typography  style={{fontWeight: 500, fontSize: '20px'}}>Nick Feffer</Typography>
                    <Typography style={{fontWeight: 100, fontSize: '15px', marginBottom:"20px"}}>Lead Software Engineer</Typography>
                    <Typography sx={{textAlign: 'left', fontSize: '16px', fontWeight: '200'}}>Nick Feffer is a computer science generalist with a background in graphics, extended reality, and visual interface design. Nick recently graduated with his Masters in Computer Science from Stanford University, focusing in Human-Computer Interaction and leads Polyview's software team.</Typography>
                 </Box>
            </Box>
            

            {/*
            <Box className={"aboutCardGrid"}>
                <Box className="about_container_card_photo2" >
                <img className='about_container_card_photo2_image'  src="/public/panos.jpeg" alt="image" style={{width: "100%", height: "100%", borderRadius:"50%" , objectFit: "cover"}}/>
                </Box>
                 <Box className="about_card2">
                    <Typography>PANOS</Typography>
                    <Typography sx={{textAlign:"center", padding:"20px"}}>Software Developer with 20 years of experience</Typography>
                 </Box>
            </Box>
            */}
            {/* <Box className="about_card2"></Box> */}
            {/* <Box className="about_card2"></Box> */}

            {/* <Box className="about_container_card">
                <Box className="about_container_card_photo" >
                <img src="/public/panos.jpeg" alt="Description" style={{width: "100%", height: "100%", borderRadius:"20px 20px 0 0" , objectFit: "cover"}} />

                    </Box>
                <Box className="about_container_card_desc" >VP of Engineering</Box>
            </Box>
            <Box className="about_container_card">
                <Box className="about_container_card_photo" >
                <img src="/public/panos.jpeg" alt="Description" style={{width: "100%", height: "100%", borderRadius:"20px 20px 0 0" , objectFit: "cover"}} />
                    </Box>
                <Box className="about_container_card_desc" >Founder & CEO</Box>
            </Box>
            <Box className="about_container_card">
                <Box className="about_container_card_photo" >
                <img src="/public/panos.jpeg" alt="Description" style={{width: "100%", height: "100%", borderRadius:"20px 20px 0 0" , objectFit: "cover"}} />
                    </Box>
                <Box className="about_container_card_desc" >Chief Technical Officer</Box>
            </Box> */}
            
        </Box>
  </Box>

    </div>


  </div>

        )
    else return (
        <div id="about" style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", marginBottom:"50px"}}>
    
    <div>
      <Box className="about" style={{width:"100vw"}}>
        <Box ref={headerRef} className={headerInView || headerSeen.current ? "about_mission" : "about_mission_hidden"}>
        Who we are
        </Box>

        <SwipeableViews enableMouseEvents style={{ width:"100vw"}} onSwitching={setDots}> 
        <Box className={"aboutCardGrid"} >
                <Box className="about_container_card_photo2_mobile" >
                <img className='about_container_card_photo2_image'  src={DimitriPic} alt="image" style={{width: "100%", height: "100%", borderRadius:"50%" , objectFit: "cover"}}/>
                </Box>
                 <Box className="about_card2_mobile">
                 <img src={text} width={"100%"}/>
                    {/*<Typography  style={{fontWeight: 500, fontSize: '20px'}}>Dimitri Arges</Typography>
                    <Typography style={{fontWeight: 100, fontSize: '15px', marginBottom:"20px"}}>Co-Chief Executive Officer</Typography>
                    <Typography sx={{textAlign: 'left', fontSize: '16px', fontWeight: '200'}}>Dimitri Arges is a serial digital health entrepreneur since industry inception, who was a founder of 4L Data Intelligence, an AI pioneer in health care fraud, waste and abuse (FWA) prevention. Dimitri also co-founded the Connected Health initiative at the UCSD Center for Wireless Communications.</Typography>
                    */}
                 </Box>
            </Box>
            <Box className={"aboutCardGrid"}>
                <Box className="about_container_card_photo2_mobile" >
                <img className='about_container_card_photo2_image'  src={JasonPic} alt="image" style={{width: "100%", height: "100%", borderRadius:"50%" , objectFit: "cover"}}/>
                </Box>
                 <Box className="about_card2_mobile">
                    <Typography  style={{fontWeight: 500, fontSize: '20px'}}>Jason Hwang</Typography>
                    <Typography style={{fontWeight: 100, fontSize: '15px', marginBottom:"20px"}}>Co-Chief Executive Officer</Typography>
                    <Typography sx={{textAlign: 'left', fontSize: '16px', fontWeight: '200'}}>Jason Hwang is an internal medicine physician who was a co-founder and chief medical officer of Lemonaid Health, a telehealth provider focused on highly-automated, asynchronous delivery of primary care. Jason is also one of the authors of The <i>Innovator's Prescription</i> and was a founder and executive director of health care research at the Clayton Christensen Institute for Disruptive Innovation.</Typography>
                 </Box>
            </Box>
            <Box className={"aboutCardGrid"}>
                <Box className="about_container_card_photo2_mobile" >
                <img className='about_container_card_photo2_image'  src={GregPic} alt="image" style={{width: "100%", height: "100%", borderRadius:"50%" , objectFit: "cover"}}/>
                </Box>
                 <Box className="about_card2_mobile">
                 <Typography style={{fontWeight: 500, fontSize: '20px'}}>Greg Deocampo</Typography>
                 <Typography style={{fontWeight: 100, fontSize: '15px', marginBottom:"20px"}}>Chief Technology Officer</Typography>

                    <Typography sx={{textAlign: 'left',
fontSize: '16px',
fontWeight: '200'}}>Greg Deocampo is a technology executive and entrepreneur whose work includes co-founding the Oscar-winning Adobe After Effects and Obscura Digital, whose projected reality systems were acquired by Madison Square Garden and deployed in the newly unveiled MSG Sphere.</Typography>
                 </Box>
            </Box>
            <Box className={"aboutCardGrid"}>
                <Box className="about_container_card_photo2_mobile" >
                <img className='about_container_card_photo2_image'  src={NickPic} alt="image" style={{width: "100%", height: "100%", borderRadius:"50%" , objectFit: "cover"}}/>
                </Box>
                 <Box className="about_card2_mobile">
                    <Typography  style={{fontWeight: 500, fontSize: '20px'}}>Nick Feffer</Typography>
                    <Typography style={{fontWeight: 100, fontSize: '15px', marginBottom:"20px"}}>Lead Software Engineer</Typography>
                    <Typography sx={{textAlign: 'left', fontSize: '16px', fontWeight: '200'}}>Nick Feffer is a computer science generalist with a background in graphics, extended reality, and visual interface design. Nick recently graduated with his Masters in Computer Science from Stanford University, focusing in Human-Computer Interaction and leads Polyview's software team.</Typography>
                 </Box>
            </Box>
            
        </SwipeableViews>
  </Box>
                {GetDots(page, 4)}
    </div>


  </div>

    )
}

export default About
