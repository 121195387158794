import * as React from 'react';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';

const CollapsingAlert = (props) => {
  //expecting props.severity
  // props.title (optional)
  // props.messageText
  let style = {}
  if (props.severity == "success"){
    style = {
    backgroundColor: 'rgb(0 93 28 / 47%)',
    color: 'white',
    marginBottom: '0px',
    marginTop: '15px'}
  }

  if (props.severity == "error"){
    style = {
    backgroundColor: '#ff000078',
    color: 'white',
    marginBottom: '0px',
    marginTop: '15px'}
  }

  return (
      <Collapse style={{width:"100%"}} in={props.open}>
        <Alert
          style={style}
          severity={props.severity}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={props.close}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          {props.message}
        </Alert>
      </Collapse>
  );
}

export default CollapsingAlert;