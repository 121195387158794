import  React, {useRef, useEffect, useState} from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import emailjs from '@emailjs/browser';
import { Box } from '@mui/material';
import './index.css'
import CollapsingAlert from '../CollapsingAlert';
import CopyrightText from '../Copyright';

import { useInView } from 'react-intersection-observer';

const ContactUs = () => {

    const {ref, inView, entry} = useInView()

    const [fromEmail, setFromEmail] = useState("")
    const [message, setMessage] = useState("")
    const [fromName, setFromName] = useState("")

    const [openAlert, setOpenAlert] = useState(false)
    const [alertMessage, setAlertMessage] = useState("Message Sent!")
    const [alertSeverity, setAlertSeverity] = useState("success")

    const seen = useRef(false)

    useEffect(() => {
        if (inView){
            seen.current = true;
        }
    }, [inView])

      const sendMail = () => {

        emailjs.send("service_4b2we8j","template_cisrvug",{
            from_name: fromName,
            message: message,
            reply_to: fromEmail
            }, "5nfq_9tvycaSC26hX");
        setFromEmail("")
        setMessage("")
        setFromName("")
        setOpenAlert(true)
      }
      
    const sendable = () => {
        return validEmail(fromEmail) && (message != "") && (fromName != "")
    }

    const validEmail = (email) => {
        return email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
    };
    
    
    let style = !sendable() ? {color:"gray"} : {}

  return (

  <Box id="contact" className="container" style={{flexDirection:"column", alignItems:"center"}}>
    <Box className={inView || seen.current ? "screen" : "screen_hidden"} ref={ref}>
      <Box className="screen-header">
        <Typography variant='h5'>Contact Us</Typography>
            <Typography variant='body1' style={{color:"#858585"}}>We'd love to get in touch with you.</Typography>

      </Box>
      <Box className="screen-body">
        
        <Box className="screen-body-item">
        <div style={{display:"flex", flexDirection:"column", gap:"10px", alignItems:"flex-end"}}>
        <CollapsingAlert style={{width:"100%"}}
                                    severity={alertSeverity}
                                    message={alertMessage}
                                    open={openAlert}
                                    close={(e) => setOpenAlert(false)}
                                    />
              <TextField required id="filled-basic" variant="filled" placeholder="Name" fullWidth value={fromName} onChange={(e) => {setFromName(e.target.value)}}/>
              <TextField required id="filled-basic" variant="filled" placeholder="Email" fullWidth value={fromEmail} onChange={(e) => {setFromEmail(e.target.value)}}/>
        
              <TextField required sx={{padding:"0px"}} style={{padding:"0px"}} multiline onChange={(e) => {setMessage(e.target.value)}} value={message} fullWidth rows={5} id="filled-basic" variant="filled" placeholder="Message" />
              
              <Button disabled={!sendable()} onClick={sendMail} style={style}
              variant="contained">
                Send</Button>
                </div>
        </Box>
      </Box>
    </Box>
    <div style={{marginTop:"50px", marginBottom:"50px"}}>
    <CopyrightText/>
    </div>
</Box>
  )
}

export default ContactUs
