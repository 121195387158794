import './App.css';
import './responsiveStyles.css';
import backdrop from './assets/animatedBackdrop.gif'
import { Landing, Mission, AboutSection, ContactSection, Toolbar, DrawerMenu } from './Sections';
import HideAppBar from './Toolbar';
import getScreenType from './getScreenType';

function App() {
  var screenType = getScreenType()


  if (screenType.type == 'desktop')
    return (
      <div className='App' >
        <HideAppBar>
          <Toolbar />
        </HideAppBar>
        <img className='backdrop-image' src={backdrop} width={"100vw"} />
        <Landing></Landing>
        <Mission></Mission>
        <AboutSection/>
        <ContactSection />
      </div>
    );
  else {
    return (
      <div className='AppMobile'>
        <DrawerMenu/>
        <Landing></Landing>
        <Mission></Mission>
        <AboutSection/>
        <ContactSection/>
      </div>
    )
  }
}

export default App;

/*
      <img className='backdrop-image' src={backdrop} width={"100vw"} />

    <Landing></Landing>
      <Mission></Mission>
      <AboutSection></AboutSection>
      <ContactSection/> */